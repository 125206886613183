import React, { useState } from 'react';
import { Text, Input, Grid, Button, Dropdown } from '@nextui-org/react';
import axios from 'axios';
import swal from 'sweetalert';

const { gapi } = window;
const CLIENT_ID = '671152913310-mb7ql91dtjp2bqoa18p2d8j0fnf1mndj.apps.googleusercontent.com';
const API_KEY = 'AIzaSyCWEnJyuFB7mGYesDjTde5iz9xCkNPUfmU';
const DISCOVERY_DOCS = [
  'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
];
const SCOPES = 'https://www.googleapis.com/auth/calendar.events';

export default function ShopRegistration() {
  const [selected, setSelected] = React.useState(new Set(['Select Branch']));
  const numbers = ['919531891302', '919434279729', '919933240857'];

  const selectedValue = React.useMemo(
    () => Array.from(selected).join(', ').replaceAll('_', ' '),
    [selected],
  );
  const [RegistrationNumber, setRegistrationNumber] = useState('');
  const [RegistrationDate, setRegistrationDate] = useState('');
  const [endDate, setendDate] = useState('');
  const [ShopDoc, setShopDoc] = useState('');
  const [EventDesc, setEventDesc] = useState('This is to remind you that your Shop Registration due date is coming close please take necessary action before your Shop Registration due date crosses');

  function guardarArchivo(e) {
    const file = e.target.files[0]; // the file
    const reader = new FileReader(); // this for convert to Base64
    reader.readAsDataURL(e.target.files[0]); // start conversion...
    // eslint-disable-next-line func-names
    reader.onload = function () {
      // .. once finished..
      const rawLog = reader.result.split(',')[1]; // extract only thee file data part
      const dataSend = {
        dataReq: { data: rawLog, name: file.name, type: file.type },
        fname: 'uploadFilesToGoogleDrive',
      }; // preapre info to send to API
      fetch(
        'https://script.google.com/macros/s/AKfycbwXuATO0YsuILYvSpjt-3s4NTAyvW-JATbLBjgRPB1wX1GbhrxKbOHY2xLkpHJ9pckh/exec', // your AppsScript URL
        { method: 'POST', body: JSON.stringify(dataSend) },
      ) // send to Api
        .then((res) => res.json())
        .then((a) => {
          setShopDoc(a.url);
          swal({
            title: 'File Uploaded!',
            text: `Check Link${a.url}`,
            icon: 'success',
            button: 'Continue',
          });
        })
        .catch((err) => swal({
          title: 'File Not Uploaded!',
          text: `error ${err}`,
          icon: 'error',
          button: 'Try Again',
        }));
    };
  }
  function sendMessages(number) {
    // eslint-disable-next-line no-restricted-syntax
    for (const num of number) {
      fetch('https://graph.facebook.com/v15.0/117079414590887/messages', {
        method: 'POST',
        headers: {
          Authorization:
            'Bearer EAAHGdZBCfZC7kBAJCSu801OfZCMdE8z3yRMxQKH3sZAExXy9j1n3VqeJbREcwYHQiMCbYDXVfxMT8jtfXUwX0dfGKdbZB9lorjWsYRYMbvZC9H2wO4MvItE0JnOCYxIuvqrxFGBBwPq2EV6T1y8kW46DFn5ZCZCG32KpuvmVu1NZAnRmYPNXOXKZBZBkyHemScpIvWU9e6yGzfikwZDZD',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          messaging_product: 'whatsapp',
          to: num,
          type: 'template',
          template: {
            name: 'hello_world ',
            language: {
              code: 'en_US',
            },
          },
        }),
      })
        .then((response) => response.json())
        // eslint-disable-next-line no-console
        .then((data) => console.log(data));
    }
  }
  const submitClick = async () => {
    await axios
      .post(
        'https://gapi.nxtunicorns.com/api/shop',
        {
          Branch: selectedValue,
          RegistrationNumber,
          RegistrationDate,
          Document: ShopDoc,
          endDate,
        },
        { headers: { 'Content-Type': 'application/json' } },
      )
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log(response);
        sendMessages(numbers);
        swal({
          title: 'New Shop Registration Submitted SuccessFully!',
          text: 'Check Data Column',
          icon: 'success',
          button: 'Continue',
        });
      })
      .catch((err) => {
        swal({
          title: 'Submit Failed!',
          text: `Network error ${err}`,
          icon: 'error',
          button: 'Try Again',
        });
      });
  };

  const createShopRegistrationDueDateReminder = async () => {
    gapi.load('client:auth2', () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        plugin_name: 'chat',
        scope: SCOPES,
      });

      // eslint-disable-next-line no-console
      gapi.client.load('calendar', 'v3', () => console.log('bam!'));

      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          const event = {
            summary: `Your Shop ${selectedValue} Branch Registration Date Ends Here with Registration No. ${RegistrationNumber} Pls check once in Software That which shop Registration Date is over`,
            location: selectedValue,
            description: EventDesc,
            start: {
              dateTime: endDate.concat('T04:30:00-00:00'),
              timeZone: 'Asia/Kolkata',
            },
            end: {
              dateTime: endDate.concat('T04:30:00-00:00'),
              timeZone: 'Asia/Kolkata',
            },
            recurrence: ['RRULE:FREQ=DAILY;COUNT=1'],
            attendees: [
              { email: 'dineshlal29@gmail.com' },
              { email: 'freelancervnd777@gmail.com' },
              { email: 'souravpaul210789@gmail.com' },
            ],
            reminders: {
              useDefault: false,
              overrides: [
                { method: 'email', minutes: 24 * 60 * 5 },
                { method: 'popup', minutes: 30 },
              ],
            },
          };

          const request = gapi.client.calendar.events.insert({
            calendarId: 'primary',
            resource: event,
          });

          // eslint-disable-next-line no-shadow
          request.execute((event) => {
            window.open(event.htmlLink);
          });

          gapi.client.calendar.events
            .list({
              calendarId: 'primary',
              timeMin: new Date().toISOString(),
              showDeleted: false,
              singleEvents: true,
              maxResults: 10,
              orderBy: 'startTime',
            })
            .then((response) => {
              const events = response.result.items;
              // eslint-disable-next-line no-console
              console.log('EVENTS: ', events);
            });
        });
    });
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Text
        h1
        size={30}
        css={{
          textGradient: '45deg, $blue600 -20%, $blue600 50%',
        }}
        weight="bold"
      >
        SHOP REGISTRATION DATA ENTRY
      </Text>
      <Grid fluid>
        <Input
          label="Registration Number"
          type="text"
          placeholder="Enter Registration Number"
          onChange={(e) => setRegistrationNumber(e.target.value)}
          value={RegistrationNumber}
        />
      </Grid>
      <p>Select Location</p>
      <Dropdown>
        <Dropdown.Button flat color="secondary" css={{ tt: 'capitalize' }}>
          {selectedValue}
        </Dropdown.Button>
        <Dropdown.Menu
          aria-label="Single selection actions"
          color="secondary"
          disallowEmptySelection
          selectionMode="single"
          selectedKeys={selected}
          onSelectionChange={setSelected}
        >
          <Dropdown.Item key="Bamboo Flat">Bamboo Flat</Dropdown.Item>
          <Dropdown.Item key="Aberdeen Bazar">Aberdeen Bazar</Dropdown.Item>
          <Dropdown.Item key="Diglipur">Diglipur</Dropdown.Item>
          <Dropdown.Item key="Bathu Basti">Bathu Basti</Dropdown.Item>
          <Dropdown.Item key="Lamba Line">Lamba Line</Dropdown.Item>
          <Dropdown.Item key="Minnie Bay">Minnie Bay</Dropdown.Item>
          <Dropdown.Item key="Goenkas Trends">Goenkas Trends</Dropdown.Item>
          <Dropdown.Item key="Zola">Zola</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <br />
      <Grid>
        <Input
          label="Upload Document"
          type="file"
          onChange={(e) => guardarArchivo(e)}
        />
      </Grid>
      <Grid>
        <Input
          width="186px"
          label="Registration Date"
          type="date"
          onChange={(e) => setRegistrationDate(e.target.value)}
          value={RegistrationDate}
        />
      </Grid>
      <Grid>
        <Input
          width="186px"
          label="Registration Due Date"
          type="date"
          onChange={(e) => setendDate(e.target.value)}
          value={endDate}
        />
      </Grid>
      <br />
      <Grid>
        <Button shadow color="primary" auto onClick={submitClick}>
          Submit
        </Button>
      </Grid>
      <br />

      <Grid>
        <br />
        <Input
          width="186px"
          label="Registration Due Date"
          type="date"
          placeholder="date"
          onChange={(e) => setendDate(e.target.value)}
          value={endDate}
        />
        <br />
        <Input
          label="Event Description"
          type="text"
          placeholder="Enter Short Event Description"
          name="EventDesc"
          onChange={(e) => setEventDesc(e.target.value)}
          value={EventDesc}
        />
        <br />
        <br />
        <Button
          shadow
          color="primary"
          onClick={createShopRegistrationDueDateReminder}
        >
          Create Reminder
        </Button>
      </Grid>
    </div>
  );
}
