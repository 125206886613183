/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { useState } from 'react';
import {
  FaFacebookF,
  // FaTwitter,
  FaInstagram,
  FaBars,
  FaTimes,
} from 'react-icons/fa';
import Glogo from '../data/goenkas.png';

import { useStateContext } from '../contexts/ContextProvider';

// function LandingPageNavbar({ handleClick }) {

function LandingPageNavbar() {
  const { setflag } = useStateContext();

  const [showMenu, setShowMenu] = useState(false);

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="w-full h-24 text-center">
      <nav className="flex justify-between items-center w-full bg-gradient-to-tr from-yellow-100 via-red-100 to-orange-100 h-full px-10">
        <div>
          <a href="/">
            <img
              src={Glogo}
              alt="Goenkas Logo"
              className="w-30 h-10 text-lg font-semibold hover:w-40 hover:h-20 hover:py-3 mx-auto"
            />
          </a>
        </div>
        <div
          className={`flex gap-10 ${
            showMenu
              ? 'flex-col items-center bg-gradient-to-tr from-yellow-100 via-red-100 to-orange-100 py-5 w-full absolute top-24 left-0'
              : 'hidden sm:flex'
          }`}
        >
          {/* <div>
            <a
              href="https://consultancy.nxtunicorns.com/"
              target="_blank"
              rel="noreferrer"
              className="text-md font-semibold text-slate-700 hover:bg-slate-700 hover:text-slate-50 hover:rounded-lg hover:py-4 hover:px-3"
            >
              Goenkas Management App
            </a>
          </div> */}
          <div className="mx-auto">
            <span
              onClick={() => setflag('login')}
              className="text-md font-semibold text-slate-700 hover:bg-slate-900/5 hover:text-slate-700 hover:rounded-lg hover:px-4  hover:py-3"
            >
              Login Into App Now!
            </span>
          </div>
        </div>
        <div
          className={`flex ${
            showMenu ? 'flex-row items-center' : 'hidden sm:flex'
          }`}
        >
          <div className="w-12 h-12 bg-white flex justify-center items-center rounded-lg mr-3">
            <a
              href="https://www.facebook.com/Goenkas.Andaman1?mibextid=ZbWKwL"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebookF className="text-xl" />
            </a>
          </div>
          {/* <div className="w-12 h-12 bg-white flex justify-center items-center rounded-lg mr-3">
            <a href="twiteer">
              <FaTwitter className="text-xl" />
            </a>
          </div> */}
          <div className="w-12 h-12 bg-white flex justify-center items-center rounded-lg mr-3">
            <a
              href="https://www.instagram.com/goenkas_andaman/"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram className="text-xl" />
            </a>
          </div>
        </div>
        <div className="sm:hidden">
          <button onClick={handleMenuClick}>
            {showMenu ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </nav>
    </div>
  );
}

export default LandingPageNavbar;
