import React from 'react';

function FooterLandingPage() {
  return (
    <div className="flex-row bg-black h-24 w-full">
      <p className="flex text-slate-50 w-full h-full justify-center items-center">
        Copyright © 2023 - All rights reserved by Goenkas
      </p>
    </div>
  );
}

export default FooterLandingPage;
