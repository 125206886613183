import { useStateContext } from '../contexts/ContextProvider';

function HeroSection() {
  const { setflag } = useStateContext();

  return (
    <div className="flex-col font-Roboto_Condensed text-slate-50 bg-hero bg-no-repeat bg-cover bg-center bg-fixed">
      {/* Add Image in this div using bg-url[] method of tailwind inside className */}
      <div className="flex-col space-y-4 pl-6 sm:pl-10 md:pl-20 lg:pl-32 pt-16 lg:pt-40">
        <p className="w-auto text-4xl">Goenkas</p>
        <p className="w-auto text-6xl">Management App</p>
        <p className="w-4/5 lg:w-2/5 text-lg pt-4 pb-4">
          With the Goenkas Management App, you can take your business to new
          heights of success, all while saving time and resources.
        </p>
        <p className="text-xl font-Roboto_Condensed w-4/5 lg:w-2/5 py-3">
          Our powerful platform offers a wide range of features designed to
          streamline your workflow, including inventory management, sales
          tracking, and customer insights.
        </p>
        <p className="w-4/5 lg:w-2/5 text-lg py-4">
          So why wait? Try the Goenkas Management App today and experience the
          difference it can make for your clothing brand!
        </p>
      </div>
      <div className="flex-col my-10 pl-6 sm:pl-10 md:pl-20 lg:pl-32 pb-60">
        <button
          type="submit"
          className="w-40 rounded-md h-14 bg-orange-200 px-4 hover:bg-slate-50 hover:text-slate-700"
        >
          <span
            onClick={() => setflag('login')}
            className="text-md font-semibold"
          >
            Check Out
          </span>
        </button>
      </div>
    </div>
  );
}

export default HeroSection;
