import React from 'react';

function Tasks() {
  return (
    <div className="flex flex-col justify-center items-center pt-60 pb-96 font-Delicious_Handrawn bg-gradient-to-bl from-red-500 via-salte-50">
      <a
        href="https://www.notion.so/Add-Tasks-910644367c26425297271ad1ecfcce3a"
        target="_blank"
        rel="noreferrer"
        className="flex-col justify-center items-center space-y-4 text-xl"
      >
        <p className="flex-col text-center">Continue To</p>
        <p className="flex-col bg-slate-900/10 px-4 rounded-lg py-4 font-Roboto_Condensed text-slate-200 hover:bg-slate-50 hover:text-slate-700 font-bold text-base">
          Employee Task Management
        </p>
      </a>
    </div>
  );
}

export default Tasks;
