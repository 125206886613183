import React, { useState } from 'react';
import { Text, Input, Grid, Button, Dropdown } from '@nextui-org/react';
import axios from 'axios';
import swal from 'sweetalert';

const { gapi } = window;
const CLIENT_ID = '671152913310-mb7ql91dtjp2bqoa18p2d8j0fnf1mndj.apps.googleusercontent.com';
const API_KEY = 'AIzaSyCWEnJyuFB7mGYesDjTde5iz9xCkNPUfmU';
const DISCOVERY_DOCS = [
  'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
];
const SCOPES = 'https://www.googleapis.com/auth/calendar.events';

export default function TelephoneBill() {
  const [selected, setSelected] = React.useState(new Set(['Select Location']));

  const selectedValue = React.useMemo(
    () => Array.from(selected).join(', ').replaceAll('_', ' '),
    [selected],
  );
  const [BillNumber, setBillNumber] = useState('');
  const [BillDate, setBillDate] = useState('');
  const [BillAmount, setBillAmount] = useState('');
  const [BillDueDate, setBillDueDate] = useState('');
  const [BillRecipt, setBillRecipt] = useState('');
  const [EventDesc, setEventDesc] = useState('This is to remind you that your bill due date is coming close please take necessary action before your bill due date crosses');

  function guardarArchivo(e) {
    const file = e.target.files[0]; // the file
    const reader = new FileReader(); // this for convert to Base64
    reader.readAsDataURL(e.target.files[0]); // start conversion...
    // eslint-disable-next-line func-names
    reader.onload = function () {
      // .. once finished..
      const rawLog = reader.result.split(',')[1]; // extract only thee file data part
      const dataSend = {
        dataReq: { data: rawLog, name: file.name, type: file.type },
        fname: 'uploadFilesToGoogleDrive',
      }; // preapre info to send to API
      fetch(
        'https://script.google.com/macros/s/AKfycbxcFjAFkEz7gtt40LFXZDnZl-VmH2_grsZ6DUbWTs9144mCQG5SPChppjU36nZyabYA/exec', // your AppsScript URL
        { method: 'POST', body: JSON.stringify(dataSend) },
      ) // send to Api
        .then((res) => res.json())
        .then((a) => {
          setBillRecipt(a.url);
          swal({
            title: 'File Uploaded!',
            text: `Check Link${a.url}`,
            icon: 'success',
            button: 'Continue',
          });
        })
        .catch((err) => swal({
          title: 'File Not Uploaded!',
          text: `error ${err}`,
          icon: 'error',
          button: 'Try Again',
        })); // Or Error in console
    };
  }
  const submitClick = async () => {
    await axios
      .post(
        'https://gapi.nxtunicorns.com/api/bill',
        {
          BillNumber,
          BillDate,
          BillAmount,
          Billtype: 'Telephone',
          BillDueDate,
          BillRecipt,
          Location: selectedValue,
        },
        { headers: { 'Content-Type': 'application/json' } },
      )
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log(response);
        swal({
          title: 'TelePhone Bill Submitted SuccessFully!',
          text: 'Check Data Column',
          icon: 'success',
          button: 'Continue',
        });
      })
      .catch((err) => {
        swal({
          title: 'Error Empty Field!',
          text: `error ${err}`,
          icon: 'error',
          button: 'Check Again',
        });
      });
  };

  const createBillDueDateReminder = async () => {
    gapi.load('client:auth2', () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        plugin_name: 'chat',
        scope: SCOPES,
      });

      // eslint-disable-next-line no-console
      gapi.client.load('calendar', 'v3', () => console.log('bam!'));

      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          const event = {
            summary: BillNumber,
            location: selectedValue,
            description: EventDesc,
            start: {
              dateTime: BillDueDate.concat('T04:30:00-00:00'),
              timeZone: 'Asia/Kolkata',
            },
            end: {
              dateTime: BillDueDate.concat('T04:30:00-00:00'),
              timeZone: 'Asia/Kolkata',
            },
            recurrence: ['RRULE:FREQ=DAILY;COUNT=1'],
            attendees: [
              { email: 'dineshlal29@gmail.com' },
              { email: 'freelancervnd777@gmail.com' },
              { email: 'souravpaul210789@gmail.com' },
            ],
            reminders: {
              useDefault: false,
              overrides: [
                { method: 'email', minutes: 24 * 60 * 2 },
                { method: 'popup', minutes: 30 },
              ],
            },
          };

          const request = gapi.client.calendar.events.insert({
            calendarId: 'primary',
            resource: event,
          });

          // eslint-disable-next-line no-shadow
          request.execute((event) => {
            window.open(event.htmlLink);
          });

          gapi.client.calendar.events
            .list({
              calendarId: 'primary',
              timeMin: new Date().toISOString(),
              showDeleted: false,
              singleEvents: true,
              maxResults: 10,
              orderBy: 'startTime',
            })
            .then((response) => {
              const events = response.result.items;
              // eslint-disable-next-line no-console
              console.log('EVENTS: ', events);
            });
        });
    });
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Text
        h1
        size={30}
        css={{
          textGradient: '45deg, $blue600 -20%, $blue600 50%',
        }}
        weight="bold"
      >
        TELEPHONE BILL ENTRY
      </Text>
      <Grid>
        <Input
          label="Bill Number"
          type="text"
          placeholder="Enter Bill Number"
          onChange={(e) => setBillNumber(e.target.value)}
          value={BillNumber}
        />
      </Grid>
      <Grid>
        <Input
          label="Bill Date"
          type="date"
          placeholder="Enter Bill Date"
          onChange={(e) => setBillDate(e.target.value)}
          value={BillDate}
        />
      </Grid>
      <Grid>
        <Input
          label="Bill Due Date"
          type="date"
          placeholder="Enter Due date"
          onChange={(e) => setBillDueDate(e.target.value)}
          value={BillDueDate}
        />
      </Grid>
      <p>Select Location</p>
      <Dropdown>
        <Dropdown.Button flat color="secondary" css={{ tt: 'capitalize' }}>
          {selectedValue}
        </Dropdown.Button>
        <Dropdown.Menu
          aria-label="Single selection actions"
          color="secondary"
          disallowEmptySelection
          selectionMode="single"
          selectedKeys={selected}
          onSelectionChange={setSelected}
        >
          <Dropdown.Item key="Bamboo Flat">Bamboo Flat</Dropdown.Item>
          <Dropdown.Item key="Aberdeen Bazar">Aberdeen Bazar</Dropdown.Item>
          <Dropdown.Item key="Diligpur">Diligpur</Dropdown.Item>
          <Dropdown.Item key="Bathu Basti">Bathu Basti</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <br />
      <Grid>
        <Input
          label="Upload File"
          type="file"
          placeholder="Upload File"
          onChange={(e) => guardarArchivo(e)}
        />
      </Grid>
      <br />
      <Grid>
        <Input
          label="Bill Amount"
          type="number"
          placeholder="Enter Amount in rs"
          onChange={(e) => setBillAmount(e.target.value)}
          value={BillAmount}
        />
      </Grid>
      <br />
      <Grid>
        <Button shadow color="primary" auto onClick={submitClick}>
          Submit
        </Button>
      </Grid>
      <br />

      <Grid>
        <br />
        <Input
          width="186px"
          label="Bill Due Date"
          type="date"
          placeholder="date"
          onChange={(e) => setBillDueDate(e.target.value)}
          value={BillDueDate}
        />
        <br />
        <Input
          label="Event Description"
          type="text"
          placeholder="Enter Short Event Description"
          name="EventDesc"
          onChange={(e) => setEventDesc(e.target.value)}
          value={EventDesc}
        />
        <br />
        <br />
        <Button shadow color="primary" onClick={createBillDueDateReminder}>
          Create Reminder
        </Button>
      </Grid>
    </div>
  );
}
