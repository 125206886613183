// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDRDZq2BE89pXXgGHDDMAgzjv2d1A3e4_8',
  authDomain: 'goenkas-management-app.firebaseapp.com',
  projectId: 'goenkas-management-app',
  storageBucket: 'goenkas-management-app.appspot.com',
  messagingSenderId: '266932987918',
  appId: '1:266932987918:web:c70ec2c853b17441d6b45f',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
