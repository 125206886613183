/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ColumnDirective, ColumnsDirective, Edit, GridComponent, Inject, Page, Toolbar } from '@syncfusion/ej2-react-grids';
import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import { useStateContext } from '../contexts/ContextProvider';

function DialogEdit() {
  const { location, isAdmin } = useStateContext();

  const toolbarOptions = ['Edit', 'Delete', 'Search'];
  const editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog' };
  const pageSettings = { pageCount: 5 };
  const [user, setUser] = useState([]);

  // const gridRef = useRef(null);
  const [uniqueLocations, setUniqueLocations] = useState([]);
  const [filtereddData, setFiltereddData] = useState(user.data || []);
  const filteredUserData = isAdmin ? filtereddData : user?.data?.filter((item) => item.Branch.toLowerCase() === location.toLowerCase());

  const fetchData = () => fetch('https://gapi.nxtunicorns.com/api/shop')
    .then((response) => response.json())
    .then((data) => { setUser(data); });

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const locations = user.data?.map((item) => item.Branch) || [];
    const uniqueLocationss = [...new Set(locations)];
    setUniqueLocations(['All', ...uniqueLocationss]);
    // setUniqueLocations(uniqueLocationss);
    setFiltereddData(user.data || []);
  }, [user.data]);

  const handleLocationFilter = (args) => {
    const selectedLocation = args.value;
    if (selectedLocation === 'All') {
      setFiltereddData(user.data);
    } else {
      const filteredData = user.data.filter((item) => item?.Branch === selectedLocation);
      setFiltereddData(filteredData);
    }
  };

  async function actionComplete(args) {
    console.log(args);
    if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
      console.log(args);
    }
    if (args.requestType === 'delete') {
      console.log(args.data[0].id);
      try {
        const response = await fetch(`https://gapi.nxtunicorns.com/api/shop/${args.data[0].id}`, {
          method: 'DELETE',
        });
        const datas = await response.json();
        if (datas !== null) {
          swal({
            title: 'Record Deleted!',
            text: 'DELETED',
            icon: 'success',
            button: 'Continue',
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
    if (args.requestType === 'edit') {
      console.log(args);
    }
  }

  return (
    <div className="control-pane pt-20 lg:pt-2">
      <div className="control-section">
        {uniqueLocations.length > 0 && (
        <div className="flex justify-end">
          <div className="select-wrap">
            <DropDownListComponent className="border" id="ddlelement" dataSource={isAdmin ? uniqueLocations : [location]} placeholder="Filter By Location" fields={{ text: 'Branch', value: 'Branch' }} value={location} readonly={!isAdmin} change={handleLocationFilter} width="200px" />
          </div>
        </div>
        )}
        <GridComponent dataSource={filteredUserData} toolbar={toolbarOptions} allowPaging editSettings={editSettings} pageSettings={pageSettings} actionComplete={actionComplete.bind(this)}>
          <ColumnsDirective>
            <ColumnDirective field="Branch" headerText="Branch" width="120" textAlign="Center" />
            <ColumnDirective field="RegistrationNumber" headerText="Registration Number" width="150" textAlign="Center" />
            <ColumnDirective field="RegistrationDate" headerText="Registration Date" width="120" textAlign="Center" format="ydM" />
            <ColumnDirective field="endDate" headerText="Registration End Date" width="120" textAlign="Center" format="ydM" />
            <ColumnDirective field="Document" headerText="Document" width="150" textAlign="Center" />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Edit]} />
        </GridComponent>
      </div>
    </div>
  );
}
export default DialogEdit;
