import React, { useRef } from 'react';

import LandingPageNavbar from './LandingPageNavbar';
import DetailedSection from './DetailedSection';
// import Login from './Login';
import HeroSection from './HeroSection';
import FooterLandingPage from './FooterLandingPage';

function LandingPage() {
  const logintakeRef = useRef(null);

  const handleClick = (id) => {
    if (id === 'takemetologin' && logintakeRef.current) {
      logintakeRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="cursor-pointer font-Roboto_Condensed">
      <LandingPageNavbar handleClick={handleClick} />
      <HeroSection />
      <DetailedSection />
      {/* <Login ref={logintakeRef} /> */}
      <FooterLandingPage />
    </div>
  );
}

export default LandingPage;
