import React from 'react';

const Footer = () => (
  <div className="mt-16 mb-16">
    <div className="flex flex-row justify-center items-center gap-2">
      <p className="dark:text-gray-200 text-gray-700 text-center">
        © 2022 All rights reserved by Team
      </p>
      <p className="dark:text-gray-200 text-gray-700 text-center">
        <a
          href="https://www.unicorniz.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          UNICORNIZ INNOVATIONS
        </a>
      </p>
    </div>
  </div>
);

export default Footer;
