import React, { useState } from 'react';
import { Text, Input, Grid, Button, Dropdown } from '@nextui-org/react';
import axios from 'axios';
import swal from 'sweetalert';

const { gapi } = window;
const CLIENT_ID = '671152913310-mb7ql91dtjp2bqoa18p2d8j0fnf1mndj.apps.googleusercontent.com';
const API_KEY = 'AIzaSyCWEnJyuFB7mGYesDjTde5iz9xCkNPUfmU';
const DISCOVERY_DOCS = [
  'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
];
const SCOPES = 'https://www.googleapis.com/auth/calendar.events';

export default function Vehicle() {
  const [selected, setSelected] = React.useState(new Set(['Select Location']));

  const selectedValue = React.useMemo(
    () => Array.from(selected).join(', ').replaceAll('_', ' '),
    [selected],
  );
  const [VehicleName, setVehicleName] = useState('');
  const [VehicleNumber, setVehicleNumber] = useState('');
  const [Insurance, setInsurance] = useState('');
  const [RoadTax, setRoadTax] = useState('');
  const [Fitness, setFitness] = useState('');
  const [PollutionTest, setPollutionTest] = useState('');
  const [File, setFile] = useState('');
  const [Location, setLocation] = useState('');
  const [InsuranceEventDesc, setInsuranceEventDesc] = useState('This is to remind you that your Vehicle Insurance End due date is coming close please take necessary action before your Vehicle Insurance End due date crosses');
  const [FitnessEventDesc, setFitnessEventDesc] = useState('This is to remind you that your Vehicle Fitness End due date is coming close please take necessary action before your Vehicle Fitness End due date crosses');
  const [RoadTaxEventDesc, setRoadTaxEventDesc] = useState('This is to remind you that your Vehicle Road Tax End due date is coming close please take necessary action before your Vehicle Road Tax End due date crosses');
  const [PollutionEventDesc, setPollutionEventDesc] = useState('This is to remind you that your Vehicle Pollution Test End due date is coming close please take necessary action before your Vehicle Pollution Test End due date crosses');
  function guardarArchivo(e) {
    const file = e.target.files[0]; // the file
    const reader = new FileReader(); // this for convert to Base64
    reader.readAsDataURL(e.target.files[0]); // start conversion...
    // eslint-disable-next-line func-names
    reader.onload = function () {
      // .. once finished..
      const rawLog = reader.result.split(',')[1]; // extract only thee file data part
      const dataSend = {
        dataReq: { data: rawLog, name: file.name, type: file.type },
        fname: 'uploadFilesToGoogleDrive',
      }; // preapre info to send to API
      fetch(
        'https://script.google.com/macros/s/AKfycbwXuATO0YsuILYvSpjt-3s4NTAyvW-JATbLBjgRPB1wX1GbhrxKbOHY2xLkpHJ9pckh/exec', // your AppsScript URL
        { method: 'POST', body: JSON.stringify(dataSend) },
      ) // send to Api
        .then((res) => res.json())
        .then((a) => {
          setFile(a.url);
          swal({
            title: 'File Uploaded!',
            text: `Check Link${a.url}`,
            icon: 'success',
            button: 'Continue',
          });
        })
        .catch((err) => swal({
          title: 'File Not Uploaded!',
          text: `error ${err}`,
          icon: 'error',
          button: 'Try Again',
        })); // Or Error in console
    };
  }
  const submitClick = async () => {
    setLocation(selectedValue);
    await axios
      .post(
        'https://gapi.nxtunicorns.com/api/vehicle',
        {
          VehicleName,
          VehicleNumber,
          Insurance,
          RoadTax,
          Fitness,
          PollutionTest,
          File,
          Location,
        },
        { headers: { 'Content-Type': 'application/json' } },
      )
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log(response);
        swal({
          title: 'Vehicle Submitted SuccessFully!',
          text: 'Check Data Column',
          icon: 'success',
          button: 'Continue',
        });
      })
      .catch((err) => {
        swal({
          title: 'Error Empty Field!',
          text: `error ${err}`,
          icon: 'error',
          button: 'Check Again',
        });
      });
  };

  const InsuranceDate = async () => {
    gapi.load('client:auth2', () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        plugin_name: 'chat',
        scope: SCOPES,
      });

      // eslint-disable-next-line no-console
      gapi.client.load('calendar', 'v3', () => console.log('bam!'));

      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          const event = {
            summary: `Your Vehicle ${VehicleName} Insurance Due Date End in 5 Days at ${selectedValue} with Registration No. ${VehicleNumber}`,
            location: selectedValue,
            description: InsuranceEventDesc,
            start: {
              dateTime: Insurance.concat('T04:30:00-00:00'),
              timeZone: 'Asia/Kolkata',
            },
            end: {
              dateTime: Insurance.concat('T04:30:00-00:00'),
              timeZone: 'Asia/Kolkata',
            },
            recurrence: ['RRULE:FREQ=DAILY;COUNT=1'],
            attendees: [
              { email: 'dineshlal29@gmail.com' },
              { email: 'freelancervnd777@gmail.com' },
              { email: 'souravpaul210789@gmail.com' },
            ],
            reminders: {
              useDefault: false,
              overrides: [
                { method: 'email', minutes: 24 * 60 * 5 },
                { method: 'popup', minutes: 30 },
              ],
            },
          };

          const request = gapi.client.calendar.events.insert({
            calendarId: 'primary',
            resource: event,
          });

          // eslint-disable-next-line no-shadow
          request.execute((event) => {
            window.open(event.htmlLink);
          });

          gapi.client.calendar.events
            .list({
              calendarId: 'primary',
              timeMin: new Date().toISOString(),
              showDeleted: false,
              singleEvents: true,
              maxResults: 10,
              orderBy: 'startTime',
            })
            .then((response) => {
              const events = response.result.items;
              // eslint-disable-next-line no-console
              console.log('EVENTS: ', events);
            });
        });
    });
  };

  const FitnessDate = () => {
    gapi.load('client:auth2', () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        plugin_name: 'chat',
        scope: SCOPES,
      });

      // eslint-disable-next-line no-console
      gapi.client.load('calendar', 'v3', () => console.log('bam!'));

      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          const event = {
            summary: `Your Vehicle ${VehicleName} Date End in 5 Days at ${selectedValue} with Registration No. ${VehicleNumber}`,
            location: selectedValue,
            description: FitnessEventDesc,
            start: {
              dateTime: Fitness.concat('T04:30:00-00:00'),
              timeZone: 'Asia/Kolkata',
            },
            end: {
              dateTime: Fitness.concat('T04:30:00-00:00'),
              timeZone: 'Asia/Kolkata',
            },
            recurrence: ['RRULE:FREQ=DAILY;COUNT=1'],
            attendees: [
              { email: 'dineshlal29@gmail.com' },
              { email: 'freelancervnd777@gmail.com' },
              { email: 'souravpaul210789@gmail.com' },
            ],
            reminders: {
              useDefault: false,
              overrides: [
                { method: 'email', minutes: 24 * 60 * 5 },
                { method: 'popup', minutes: 30 },
              ],
            },
          };

          const request = gapi.client.calendar.events.insert({
            calendarId: 'primary',
            resource: event,
          });

          // eslint-disable-next-line no-shadow
          request.execute((event) => {
            window.open(event.htmlLink);
          });

          gapi.client.calendar.events
            .list({
              calendarId: 'primary',
              timeMin: new Date().toISOString(),
              showDeleted: false,
              singleEvents: true,
              maxResults: 10,
              orderBy: 'startTime',
            })
            .then((response) => {
              const events = response.result.items;
              // eslint-disable-next-line no-console
              console.log('EVENTS: ', events);
            });
        });
    });
  };

  const RoadTaxDate = () => {
    gapi.load('client:auth2', () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        plugin_name: 'chat',
        scope: SCOPES,
      });

      // eslint-disable-next-line no-console
      gapi.client.load('calendar', 'v3', () => console.log('bam!'));

      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          const event = {
            summary: `Your Vehicle ${VehicleName} Date End in 5 Days at ${selectedValue} with Registration No. ${VehicleNumber}`,
            location: selectedValue,
            description: RoadTaxEventDesc,
            start: {
              dateTime: RoadTax.concat('T04:30:00-00:00'),
              timeZone: 'Asia/Kolkata',
            },
            end: {
              dateTime: RoadTax.concat('T04:30:00-00:00'),
              timeZone: 'Asia/Kolkata',
            },
            recurrence: ['RRULE:FREQ=DAILY;COUNT=1'],
            attendees: [
              { email: 'dineshlal29@gmail.com' },
              { email: 'freelancervnd777@gmail.com' },
              { email: 'souravpaul210789@gmail.com' },
            ],
            reminders: {
              useDefault: false,
              overrides: [
                { method: 'email', minutes: 24 * 60 * 5 },
                { method: 'popup', minutes: 30 },
              ],
            },
          };

          const request = gapi.client.calendar.events.insert({
            calendarId: 'primary',
            resource: event,
          });

          // eslint-disable-next-line no-shadow
          request.execute((event) => {
            window.open(event.htmlLink);
          });

          gapi.client.calendar.events
            .list({
              calendarId: 'primary',
              timeMin: new Date().toISOString(),
              showDeleted: false,
              singleEvents: true,
              maxResults: 10,
              orderBy: 'startTime',
            })
            .then((response) => {
              const events = response.result.items;
              // eslint-disable-next-line no-console
              console.log('EVENTS: ', events);
            });
        });
    });
  };

  const PollutionTestDate = () => {
    gapi.load('client:auth2', () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        plugin_name: 'chat',
        scope: SCOPES,
      });

      // eslint-disable-next-line no-console
      gapi.client.load('calendar', 'v3', () => console.log('bam!'));

      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          const event = {
            summary: `Your Vehicle ${VehicleName} Date End in 5 Days at ${selectedValue} with Registration No. ${VehicleNumber}`,
            location: selectedValue,
            description: PollutionEventDesc,
            start: {
              dateTime: PollutionTest.concat('T04:30:00-00:00'),
              timeZone: 'Asia/Kolkata',
            },
            end: {
              dateTime: PollutionTest.concat('T04:30:00-00:00'),
              timeZone: 'Asia/Kolkata',
            },
            recurrence: ['RRULE:FREQ=DAILY;COUNT=1'],
            attendees: [
              { email: 'dineshlal29@gmail.com' },
              { email: 'freelancervnd777@gmail.com' },
              { email: 'souravpaul210789@gmail.com' },
            ],
            reminders: {
              useDefault: false,
              overrides: [
                { method: 'email', minutes: 24 * 60 * 5 },
                { method: 'popup', minutes: 30 },
              ],
            },
          };

          const request = gapi.client.calendar.events.insert({
            calendarId: 'primary',
            resource: event,
          });

          // eslint-disable-next-line no-shadow
          request.execute((event) => {
            window.open(event.htmlLink);
          });

          gapi.client.calendar.events
            .list({
              calendarId: 'primary',
              timeMin: new Date().toISOString(),
              showDeleted: false,
              singleEvents: true,
              maxResults: 10,
              orderBy: 'startTime',
            })
            .then((response) => {
              const events = response.result.items;
              // eslint-disable-next-line no-console
              console.log('EVENTS: ', events);
            });
        });
    });
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Text
        h1
        size={30}
        css={{
          textGradient: '45deg, $blue600 -20%, $blue600 50%',
        }}
        weight="bold"
      >
        VEHICLE DATA ENTRY
      </Text>
      <Grid fluid>
        <Input
          label="Name of Vehicle"
          type="text"
          placeholder="Enter Vehicle Name"
          onChange={(e) => setVehicleName(e.target.value)}
          value={VehicleName}
        />
      </Grid>
      <Grid>
        <Input
          label="Vehicle Number"
          type="text"
          placeholder="Enter Vehicle Number"
          onChange={(e) => setVehicleNumber(e.target.value)}
          value={VehicleNumber}
        />
      </Grid>
      <Grid>
        <Input
          label="Upload Vehicle Documents"
          type="file"
          onChange={(e) => guardarArchivo(e)}
        />
      </Grid>
      <br />
      <p>Select Location</p>
      <Dropdown>
        <Dropdown.Button flat color="secondary" css={{ tt: 'capitalize' }}>
          {selectedValue}
        </Dropdown.Button>
        <Dropdown.Menu
          aria-label="Single selection actions"
          color="secondary"
          disallowEmptySelection
          selectionMode="single"
          selectedKeys={selected}
          onSelectionChange={setSelected}
        >
          <Dropdown.Item key="Bamboo Flat">Bamboo Flat</Dropdown.Item>
          <Dropdown.Item key="Aberdeen Bazar">Aberdeen Bazar</Dropdown.Item>
          <Dropdown.Item key="Diglipur">Diglipur</Dropdown.Item>
          <Dropdown.Item key="Bathu Basti">Bathu Basti</Dropdown.Item>
          <Dropdown.Item key="Lamba Line">Lamba Line</Dropdown.Item>
          <Dropdown.Item key="Minnie Bay">Minnie Bay</Dropdown.Item>
          <Dropdown.Item key="Goenkas Trends">Goenkas Trends</Dropdown.Item>
          <Dropdown.Item key="Zola">Zola</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <br />
      <Grid>
        <Input
          width="186px"
          label="Insurance End Date"
          type="date"
          onChange={(e) => setInsurance(e.target.value)}
          value={Insurance}
        />
      </Grid>
      <Grid>
        <Input
          width="186px"
          label="Fitness End Date"
          type="date"
          onChange={(e) => setFitness(e.target.value)}
          value={Fitness}
        />
      </Grid>
      <Grid>
        <Input
          width="186px"
          label="Road Tax End date"
          type="date"
          onChange={(e) => setRoadTax(e.target.value)}
          value={RoadTax}
        />
      </Grid>
      <Grid>
        <Input
          width="186px"
          label="Pollution Test End Date"
          type="date"
          onChange={(e) => setPollutionTest(e.target.value)}
          value={PollutionTest}
        />
      </Grid>
      <br />
      <Grid>
        <Button shadow color="primary" auto onClick={submitClick}>
          Submit
        </Button>
      </Grid>
      <br />

      <Grid>
        <br />
        <Input
          width="186px"
          label="Insurance Date"
          type="date"
          placeholder="date"
          onChange={(e) => setInsurance(e.target.value)}
          value={Insurance}
        />
        <br />
        <Input
          label="Event Description"
          type="text"
          placeholder="Enter Short Event Description"
          name="InsuranceEventDesc"
          onChange={(e) => setInsuranceEventDesc(e.target.value)}
          value={InsuranceEventDesc}
        />
        <br />
        <br />
        <Button shadow color="primary" onClick={InsuranceDate}>
          Create Reminder For Insurance Due Date
        </Button>
        <br />
        <Input
          width="186px"
          label="Fitness Date"
          type="date"
          placeholder="date"
          onChange={(e) => setFitness(e.target.value)}
          value={Fitness}
        />
        <br />
        <Input
          label="Event Description"
          type="text"
          placeholder="Enter Short Event Description"
          name="FitnessEventDesc"
          onChange={(e) => setFitnessEventDesc(e.target.value)}
          value={FitnessEventDesc}
        />
        <br />
        <br />
        <Button shadow color="primary" onClick={FitnessDate}>
          Create Reminder For Fitness Test Due Date
        </Button>
        <br />
        <Input
          width="186px"
          label="Road Tax Date"
          type="date"
          placeholder="date"
          onChange={(e) => setRoadTax(e.target.value)}
          value={RoadTax}
        />
        <br />
        <Input
          label="Event Description"
          type="text"
          placeholder="Enter Short Event Description"
          name="RoadTaxEventDesc"
          onChange={(e) => setRoadTaxEventDesc(e.target.value)}
          value={RoadTaxEventDesc}
        />
        <br />
        <br />
        <Button shadow color="primary" onClick={RoadTaxDate}>
          Create Reminder For Road Tax Due Date
        </Button>
        <br />
        <Input
          width="186px"
          label="Pollution Test Date"
          type="date"
          placeholder="date"
          onChange={(e) => setPollutionTest(e.target.value)}
          value={PollutionTest}
        />
        <br />
        <Input
          label="Event Description"
          type="text"
          placeholder="Enter Short Event Description"
          name="PollutionEventDesc"
          onChange={(e) => setPollutionEventDesc(e.target.value)}
          value={PollutionEventDesc}
        />
        <br />
        <br />
        <Button shadow color="primary" onClick={PollutionTestDate}>
          Create Reminder For Pollution Test Due Date
        </Button>
      </Grid>
    </div>
  );
}
