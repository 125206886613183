import React, { useState } from "react";
import { Text, Input, Grid, Button } from "@nextui-org/react";
import axios from "axios";
import swal from "sweetalert";
// import { useStateContext } from "../contexts/ContextProvider";

export default function PartyDiscountPage() {
  // const { location } = useStateContext();

  const [PartyName, setPartyName] = useState("");
  const [PartyDiscount, setPartyDisc] = useState("");
  const [Address, setAddress] = useState("");
  // const filteredUserData = user?.data?.filter(item=>item.AssetLocation.toLowerCase()===location.toLowerCase());
  const submitClick = async () => {
    await axios
      .post("https://gapi.nxtunicorns.com/api/partydiscount", {
        PartyName,
        PartyDiscount,
        Address,
      })
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log(response);
        swal({
          title: "Party Discount Submitted SuccessFully!",
          text: "Check Data Column",
          icon: "success",
          button: "Continue",
        });
      })
      .catch((err) => {
        swal({
          title: "Error Empty Field!",
          text: `error ${err}`,
          icon: "error",
          button: "Check Again",
        });
      });
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Text
        h1
        size={30}
        css={{
          textGradient: "45deg, $blue600 -20%, $blue600 50%",
        }}
        weight="bold"
      >
        PARTY DISCOUNT ENTRY
      </Text>
      <Grid>
        <Input
          label="Party Name"
          type="text"
          placeholder="Enter Party Name"
          onChange={(e) => setPartyName(e.target.value)}
          value={PartyName}
        />
      </Grid>
      <Grid>
        <Input
          label="Discount Percentage"
          type="number"
          placeholder="Enter Discount %"
          onChange={(e) => setPartyDisc(e.target.value)}
          value={PartyDiscount}
        />
      </Grid>
      <Grid>
        <Input
          width="186px"
          label="Address"
          type="text"
          placeholder="Enter Address"
          onChange={(e) => setAddress(e.target.value)}
          value={Address}
        />
      </Grid>
      <Grid>
        <br />
        <Button shadow color="primary" auto onClick={submitClick}>
          Submit
        </Button>
      </Grid>
    </div>
  );
}
