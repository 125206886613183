/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-unresolved */
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ColumnDirective, ColumnsDirective, Edit, GridComponent, Inject, Page, Toolbar } from '@syncfusion/ej2-react-grids';
import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import { useStateContext } from '../contexts/ContextProvider';

function DialogEdit() {
  const { location, isAdmin } = useStateContext();

  const toolbarOptions = ['Edit', 'Delete', 'Search'];
  const editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog' };
  const pageSettings = { pageCount: 5 };
  const [user, setUser] = useState([]);

  // const gridRef = useRef(null);
  const [uniqueLocations, setUniqueLocations] = useState([]);
  const [filtereddData, setFiltereddData] = useState(user.data || []);

  const fetchData = () => fetch('https://gapi.nxtunicorns.com/api/vehicle')
    .then((response) => response.json())
    .then((data) => setUser(data));

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const locations = user.data?.map((item) => item.Location) || [];
    const uniqueLocationss = [...new Set(locations)];
    setUniqueLocations(['All', ...uniqueLocationss]);
    // setUniqueLocations(uniqueLocationss);
    setFiltereddData(user.data || []);
  }, [user.data]);

  const filteredUserData = isAdmin ? filtereddData : user?.data?.filter((item) => item.Location.toLowerCase() === location.toLowerCase());

  const handleLocationFilter = (args) => {
    const selectedLocation = args.value;
    if (selectedLocation === 'All') {
      setFiltereddData(user.data);
    } else {
      const filteredData = user.data.filter((item) => item.Location === selectedLocation);
      setFiltereddData(filteredData);
    }
  };
  async function actionComplete(args) {
    console.log(args);
    if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
      console.log(args);
    }
    if (args.requestType === 'delete') {
      console.log(args.data[0].id);
      try {
        const response = await fetch(`https://gapi.nxtunicorns.com/api/vehicle/${args.data[0].id}`, {
          method: 'DELETE',
        });
        const datas = await response.json();
        if (datas !== null) {
          swal({
            title: 'Record Deleted!',
            text: 'DELETED',
            icon: 'success',
            button: 'Continue',
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
    if (args.requestType === 'edit') {
      console.log(args);
    }
  }

  return (
    <div className="control-pane pt-20 lg:pt-2">
      <div className="control-section">
        <GridComponent dataSource={filteredUserData} toolbar={toolbarOptions} allowPaging editSettings={editSettings} pageSettings={pageSettings} actionComplete={actionComplete.bind(this)}>
          <ColumnsDirective>
            <ColumnDirective field="VehicleName" headerText="Vehicle Name" width="120" textAlign="Center" />
            <ColumnDirective field="VehicleNumber" headerText="Vehicle Number" width="150" textAlign="Center" />
            <ColumnDirective field="Insurance" headerText="Insurance End Date" width="120" textAlign="Center" format="ydM" />
            <ColumnDirective field="RoadTax" headerText="Road-Tax End Date" width="120" textAlign="Center" format="ydM" />
            <ColumnDirective field="Fitness" headerText="Fitness End Date" width="120" textAlign="Center" format="ydM" />
            <ColumnDirective field="PollutionTest" headerText="Pollution Test End Date" width="120" textAlign="Center" format="ydM" />
            <ColumnDirective field="File" headerText="Document" width="150" textAlign="Center" />
            <ColumnDirective field="Location" headerText="Location" width="150" textAlign="Center" />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Edit]} />
          <div className="flex justify-end">
            <div className="select-wrap">
              {uniqueLocations.length > 0 && (
              <DropDownListComponent
                className="border"
                id="ddlelement"
                dataSource={isAdmin ? uniqueLocations : [location]}
                readonly={!isAdmin}
                placeholder="Filter By Location"
                fields={{ text: 'Location', value: 'Location' }}
                value={location}
                change={handleLocationFilter}
                width="200px"
              />
              )}
            </div>
          </div>
        </GridComponent>
      </div>
    </div>
  );
}
export default DialogEdit;
