import { useMemo, useState } from "react";
// import { useNavigate } from 'react-router-dom';

import { Dropdown } from "@nextui-org/react";
import swal from "sweetalert";

// import {
//   Card,
//   Spacer,
//   Button,
//   Text,
//   Input,
//   Container,
//   // Grid,
//   // Image,
// } from '@nextui-org/react';

// import { Mail } from './Mail';
// import { Password } from './Password';

// import loginn from '../data/login.jpg';
import glogo from "../data/goenkas2.png";
// import background from '../data/bglogReg.png';

import { useStateContext } from "../contexts/ContextProvider";

const LoginPage = () => {
  // const navigate = useNavigate();

  const {
    // setCurrentColor,
    // setCurrentMode,
    // currentMode,
    // activeMenu,
    // currentColor,
    // themeSettings,
    // setThemeSettings,
    // flag,
    setflag,
    // UserName,
    // setUserName,
    // PasswordIn,
    // setPassword,
    login,
    isAdmin,
    setLocation,
  } = useStateContext();

  const [selected, setSelected] = useState(new Set(["Select Branch"]));

  const selectedValue = useMemo(
    () => Array.from(selected).join(", ").replaceAll("_", " "),
    [selected]
  );

  // const [name, setName] = useState('');
  // const [userPassword, setUserPassword] = useState('');

  // const loginUser = async (event) => {
  //   if (data.user) {
  //     localStorage.setItem('token', data.user);
  //     alert('Login successful');
  //     window.location.href = '/ecommerce';
  //   } else {
  //     alert('Wrong User credentials');
  //   }
  // };

  const loginUser = (event) => {
    event.preventDefault();
    const from = event.target;
    const email = from.email.value;
    const password = from.password.value;
    const emailBranchMap = {
      "admin567@gmail.com": "All",
      "rakesh123@gmail.com": "Bathu Basti",
      "ramya123@gmail.com": "Bamboo Flat",
      "govind123@gmail.com": "Aberdeen Bazar",
      "rajni123@gmail.com": "Goenkas Trends",
      "subroto123@gmail.com": "Diglipur",
      "santoshzola123@gmail.com": "Zola", // password is santzola123
    };

    const errModal = (branchName) => {
      swal({
        title: "Wrong Branch Name!",
        text: `Please Select ${branchName} Branch`,
        icon: "error",
        button: "Please Try Again",
      });
    };

    const correctBranchName = emailBranchMap[email];

    if (correctBranchName && selectedValue !== correctBranchName) {
      return errModal(correctBranchName);
    }

    login(email, password)
      .then(({ user }) => {
        // eslint-disable-next-line no-console
        console.log(user);
        setLocation(selectedValue);
        if (user?.email === "admin567@gmail.com") {
          setflag("login2");
        } else if (user) {
          setflag("login1");
        }
      })
      // eslint-disable-next-line no-shadow
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        if (error) {
          swal({
            title: "Login Failed!",
            text: "Incorrect Username or Password!",
            icon: "error",
            button: "Please Try Again!",
          });
        }
      });

    // const response = await fetch('http://localhost:5000/login', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({
    //     name,
    //     userPassword,
    //   }),
    // });

    // const data = await response.json();

    // if (data.user) {
    //   localStorage.setItem('token', data.user);
    //   setflag('login1');
    //   // navigate('/ecommerce');
    // } else if (
    //   data.user.name === 'rakesh' && data.user.password === 'rakesh@123'
    // ) {
    //   setflag('login2');
    // } else {
    //   swal({
    //     title: 'Login Failed!',
    //     text: 'Incorrect Username or Password!',
    //     icon: 'error',
    //     button: 'Please Try Again!',
    //   });
    // }

    // if (data.user) {
    //   localStorage.setItem('token', data.user);
    //   alert('Login successful');
    //   window.location.href = '/ecommerce';
    // } else {
    //   alert('Wrong User credentials');
    // }
  };
  return (
    <div
      id="takemetologin"
      className="w-full bg-gradient-to-bl from-red-500 via-salte-50 px-10 font-Roboto_Condensed"
      //   style={
      // { backgroundImage: `url(${background})`,
      // }
      //   }
    >
      <div className="w-11/12 h-screen mx-auto flex justify-center items-center">
        <div className="hidden md:block lg:block">
          <img
            className="rounded-l-lg w-full"
            src="https://images.unsplash.com/photo-1524758631624-e2822e304c36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
            alt="bgImage"
          />
        </div>
        <div className="bg-white lg:flex-row h-5/6 rounded-lg w-full lg:w-6/12 mx-auto px-14 py-10">
          <img className="w-48 mx-auto mt-0" src={glogo} alt="goenka's logo" />
          <div className="w-full px-3 sm:px-0 md:px-6 my-10">
            <h2 className="text-3xl mx-auto mb-0 text-slate-900 italic font-medium">
              Sign In
            </h2>
            <form onSubmit={loginUser}>
              <div className="mt-5">
                <p className="text-slate-600 font-light text-lg mb-2">Email</p>
                <input
                  type="email"
                  className="border-b-2 border-blue-900 bg-transparent outline-none px-2 w-full text-slate-700"
                  name="email"
                />
              </div>
              <div className="mt-5">
                <p className="text-slate-600 font-light text-lg mb-2">
                  Password
                </p>
                <input
                  type="password"
                  className="border-b-2 border-blue-900 bg-transparent outline-none px-2 w-full text-slate-700"
                  name="password"
                />
              </div>
              <br />
              <p className="text-slate-600 font-light text-lg mb-2">
                Select Your Branch
              </p>
              <Dropdown>
                <Dropdown.Button
                  flat
                  color="secondary"
                  css={{ tt: "capitalize" }}
                >
                  {selectedValue}
                </Dropdown.Button>
                <Dropdown.Menu
                  aria-label="Single selection actions"
                  color="secondary"
                  disallowEmptySelection
                  selectionMode="single"
                  selectedKeys={selected}
                  onSelectionChange={setSelected}
                >
                  <Dropdown.Item key="All">All</Dropdown.Item>
                  <Dropdown.Item key="Bamboo Flat">Bamboo Flat</Dropdown.Item>
                  <Dropdown.Item key="Aberdeen Bazar">
                    Aberdeen Bazar
                  </Dropdown.Item>
                  <Dropdown.Item key="Diglipur">Diglipur</Dropdown.Item>
                  <Dropdown.Item key="Bathu Basti">Bathu Basti</Dropdown.Item>
                  <Dropdown.Item key="Lamba Line">Lamba Line</Dropdown.Item>
                  <Dropdown.Item key="Minnie Bay">Minnie Bay</Dropdown.Item>
                  <Dropdown.Item key="Goenkas Trends">
                    Goenkas Trends
                  </Dropdown.Item>
                  <Dropdown.Item key="Zola">Zola</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className="mt-10 md:mt-14 sm:mt-10 text-center">
                <input
                  type="submit"
                  className="w-4/5 bg-gradient-to-tr from-blue-500 via-blue-600 to-blue-700 py-3 text-center text-white text-base cursor-pointer rounded-full"
                  value="Login Now"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
