/* eslint-disable react/jsx-no-useless-fragment */

import React, { useState } from 'react';
import { Text, Radio, Input, Grid, Button, Dropdown } from '@nextui-org/react';
import axios from 'axios';
import swal from 'sweetalert';

export default function NewAssest() {
  const [selected, setSelected] = React.useState(new Set(['Select Branch']));

  const selectedValue = React.useMemo(() => Array.from(selected).join(', ').replaceAll('_', ' '), [selected]);
  const [AssetName, setAssestName] = useState('');
  const [AssetValue, setAssestValue] = useState('');
  const [BrandName, setBrandName] = useState('');
  const [AssetQuantity, setQuantity] = useState('');
  const [DateOfPurchase, setPurchaseDate] = useState('');
  const [BillNumber, setBillNo] = useState('');
  const [AssetWarranty, setWarranty] = useState('');
  const [AsssetWarrentyPeriodVal, setWarrantyPeriod] = useState('');
  const [AssetType, setAssestType] = useState('');
  const [AssetBill, setAssestBill] = useState('');
  const [PartyName, setPartyName] = useState('');
  function guardarArchivo(e) {
    const file = e.target.files[0]; // the file
    const reader = new FileReader(); // this for convert to Base64
    reader.readAsDataURL(e.target.files[0]); // start conversion...
    // eslint-disable-next-line func-names
    reader.onload = function () { // .. once finished..
      const rawLog = reader.result.split(',')[1]; // extract only thee file data part
      const dataSend = { dataReq: { data: rawLog, name: file.name, type: file.type }, fname: 'uploadFilesToGoogleDrive' }; // preapre info to send to API
      fetch(
        'https://script.google.com/macros/s/AKfycbwXuATO0YsuILYvSpjt-3s4NTAyvW-JATbLBjgRPB1wX1GbhrxKbOHY2xLkpHJ9pckh/exec', // your AppsScript URL
        { method: 'POST', body: JSON.stringify(dataSend) },
      ) // send to Api
        .then((res) => res.json()).then((a) => {
          setAssestBill((a.url));
          swal({
            title: 'File Uploaded!',
            text: `Check Link${a.url}`,
            icon: 'success',
            button: 'Continue',
          });
        }).catch((err) => swal({
          title: 'File Not Uploaded!',
          text: `error ${err}`,
          icon: 'error',
          button: 'Try Again',
        })); // Or Error in console
    };
  }
  const submitClick = async () => {
    if (AssetWarranty === 'NO') {
      setWarrantyPeriod('00-00-0000');
    }
    await axios
      .post('https://gapi.nxtunicorns.com/api/assets', {
        AssetName,
        AssetValue,
        AssetLocation: selectedValue,
        AssetQuantity,
        DateOfPurchase,
        AssetWarranty,
        BillNumber,
        AssetType,
        BrandName,
        AssetBill,
        PartyName,
        AsssetWarrentyPeriod: AsssetWarrentyPeriodVal || '00-00-0000',
      }, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log(response);
        swal({
          title: 'New Asset Submitted SuccessFully!',
          text: 'Check Data Column',
          icon: 'success',
          button: 'Continue',
        });
      })
      .catch((err) => {
        swal({
          title: 'Submit Failed!',
          text: `Network error ${err}`,
          icon: 'error',
          button: 'Try Again',
        });
      });
  };

  return (
    <form method="post" encType="multipart/form-data">
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        <Text
          h1
          size={30}
          css={{
            textGradient: '45deg, $blue600 -20%, $blue600 50%',
          }}
          weight="bold"
        >
          ASSET DATA ENTRY
        </Text>
        <Grid>
          <Input label="Name of Asset" name="AssetName" type="text" placeholder="Enter the Asset Name" onChange={(e) => setAssestName(e.target.value)} value={AssetName} />
        </Grid>
        <Grid>
          <Input label="Name of Brand" name="BrandName" type="text" placeholder="Enter the Brand" onChange={(e) => setBrandName(e.target.value)} value={BrandName} />
        </Grid>
        <Grid>
          <Input label="Asset Price" name="AssetValue" type="text" placeholder="Asset Value" onChange={(e) => setAssestValue(e.target.value)} value={AssetValue} />
        </Grid>
        <Grid>
          <Input label="Purchaser Name" name="PartyName" type="text" placeholder="Party Name" onChange={(e) => setPartyName(e.target.value)} value={PartyName} />
        </Grid>
        <Grid>
          <Input label="Bill Number" name="BillNumber" type="text" placeholder="Enter bill Number" onChange={(e) => setBillNo(e.target.value)} value={BillNumber} />
        </Grid>
        <Grid>
          <Input label="Upload Bill" type="file" name="AssetBill" id="AssetBill" placeholder="Upload File" onChange={(e) => guardarArchivo(e)} />
        </Grid><br />
        <Grid>
          <Input label="Asset Quantity" name="AssetQuantity" type="number" placeholder="Enter Asset Quantity" onChange={(e) => setQuantity(e.target.value)} value={AssetQuantity} />
        </Grid>
        <Grid>
          <Input width="186px" name="DateOfPurchase" label="Date of Purchase" type="date" placeholder="Enter Date of Purchase" onChange={(e) => setPurchaseDate(e.target.value)} value={DateOfPurchase} />
        </Grid>
        <Radio.Group label="Assest Type" value={AssetType} name="AssetType" onChange={setAssestType} size="xs">
          <Radio value="FIXED">FIXED</Radio>
          <Radio value="VARIABLE">VARIABLE</Radio>
        </Radio.Group>
        <Radio.Group label="Warranty" value={AssetWarranty} defaultValue="NO" name="AssetWarranty" onChange={setWarranty} size="xs">
          <Radio value="YES">Yes</Radio>
          <Radio value="NO">No</Radio>
        </Radio.Group>
        {AssetWarranty === 'YES' ? (
          <Grid>
            <Input width="186px" name="AsssetWarrentyPeriod" label="Warranty Period" type="text" placeholder="Warranty Period (months/year)" onChange={(e) => setWarrantyPeriod(e.target.value)} value={AsssetWarrentyPeriodVal} />
          </Grid>
        ) : <>{' '}</>}
        <p>Select Branch</p>
        <Dropdown>
          <Dropdown.Button flat color="secondary" css={{ tt: 'capitalize' }}>
            {selectedValue}
          </Dropdown.Button>
          <Dropdown.Menu
            aria-label="Single selection actions"
            color="secondary"
            disallowEmptySelection
            selectionMode="single"
            selectedKeys={selected}
            onSelectionChange={setSelected}
          >
            <Dropdown.Item key="Bamboo Flat">Bamboo Flat</Dropdown.Item>
            <Dropdown.Item key="Aberdeen Bazar">Aberdeen Bazar</Dropdown.Item>
            <Dropdown.Item key="Diglipur">Diglipur</Dropdown.Item>
            <Dropdown.Item key="Bathu Basti">Bathu Basti</Dropdown.Item>
            <Dropdown.Item key="Lamba Line">Lamba Line</Dropdown.Item>
            <Dropdown.Item key="Minnie Bay">Minnie Bay</Dropdown.Item>
            <Dropdown.Item key="Goenkas Trends">Goenkas Trends</Dropdown.Item>
            <Dropdown.Item key="Zola">Zola</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown><br />
        <Grid>
          <Button shadow color="primary" auto onClick={submitClick} accept="application/pdf">Submit</Button>
        </Grid>
      </div>
    </form>
  );
}
