import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

// import {
//   Card,
//   Spacer,
//   Button,
//   Text,
//   Input,
//   Container,
//   // Grid,
//   // Image,
// } from '@nextui-org/react';

// import swal from 'sweetalert';
import { Navbar, Footer, Sidebar, ThemeSettings } from './components';
import AdminSidebar from './components/AdminSidebar';

import {
  Ecommerce,
  Orders,
  Employees,
  // Stacked,
  // Pyramid,
  Customers,
  // Kanban,
  // Line,
  // Bar,
  // Pie,
  // Financial,
  // ColorPicker,
  // ColorMapping,
  // Editor,
} from './pages';
import './App.css';

import Register from './pages/Register';
import Insurance from './pages/Insurance';
import Vehicle from './pages/Vehicle';
import TaxPayment from './pages/TaxPayment';
import Reminder from './pages/CreateReminder';
import ShopRegistration from './pages/ShopRegistration';
import NewEmployee from './pages/NewEmployee';
import NewAssest from './pages/NewAssest';
import ElectricBill from './pages/ElectricBill';
import TelephoneBill from './pages/Telephonebill';
import PartyDiscount from './pages/Partydiscount';
import Insurancedata from './pages/Insurancedata';
import ViewTax from './pages/ViewTax';
import ViewVehicle from './pages/ViewVehicle';
import ViewPartyDiscount from './pages/ViewPartyDiscount';
import ViewShopRegistration from './pages/ViewShopRegistration';
import ViewElectric from './pages/ViewElectric';
import LandingPage from './pages/LandingPage';
// import PageNotFound from './pages/PageNotFound';

// import gbf from './data/bf.jpeg';
// import gd from './data/dili.jpeg';
// import gbb from './data/bb.jpeg';
// import gab from './data/ab.jpeg';

import { useStateContext } from './contexts/ContextProvider';
import LoginPage from './pages/LoginPage';
import Tasks from './pages/Tasks';

const App = () => {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
    flag,
    // setflag,
    // UserName,
    // setUserName,
    // PasswordIn,
    // setPassword,
  } = useStateContext();
  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);
  // const login = () => {
  //   if (UserName === 'dinesh' && PasswordIn === '123') {
  //     setflag('login1');
  //   } else if (UserName === 'goenkas' && PasswordIn === 'GKS@123') {
  //     setflag('login2');
  //   } else if (UserName === 'rakesh' && PasswordIn === 'rakesh@123') {
  //     setflag('login2');
  //   } else {
  //     swal({
  //       title: 'Login Failed!',
  //       text: 'Incorrect Username or Password!',
  //       icon: 'error',
  //       button: 'Please Try Again!',
  //     });
  //   }
  // };
  // const dashboard = (event) => {
  //   event.preventDefault();
  //   setflag('dashboard');
  // };
  // if (flag === 'login') {
  //   return (
  //     <div>
  //       <Grid.Container gap={2} justify="center" fluid>
  //         <Grid fluid>
  //           <Image
  //             width={300}
  //             height={240}
  //             src={gd}
  //             alt="Goenkas img"
  //             objectFit="cover"
  //             onClick={dashboard}
  //           />
  //           <center>
  //             <h1>
  //               <b>Goenkas Diglipur</b>
  //             </h1>
  //           </center>
  //         </Grid>
  //         <Grid fluid>
  //           <Image
  //             width={300}
  //             height={240}
  //             src={gbf}
  //             alt="Goenkas img"
  //             objectFit="cover"
  //             onClick={dashboard}
  //           />
  //           <center>
  //             <h1>
  //               <b>Goenkas Bambooflat</b>
  //             </h1>
  //           </center>
  //         </Grid>
  //         <Container gap={0} css={{ d: 'flex', flexWrap: 'nowrap' }}>
  //           <Spacer x={2} />
  //         </Container>
  //         <Grid fluid>
  //           <Image
  //             width={300}
  //             height={240}
  //             src={gbb}
  //             alt="Goenkas img"
  //             objectFit="cover"
  //             onClick={dashboard}
  //           />
  //           <center>
  //             <h1>
  //               <b>Goenkas BathuBasti</b>
  //             </h1>
  //           </center>
  //         </Grid>
  //         <Grid fluid>
  //           <Image
  //             width={300}
  //             height={240}
  //             src={gab}
  //             alt="Goenkas img"
  //             objectFit="cover"
  //             onClick={dashboard}
  //           />
  //           <center>
  //             <h1>
  //               <b>Goenkas Aberdeen Bazar</b>
  //             </h1>
  //           </center>
  //         </Grid>
  //       </Grid.Container>
  //     </div>
  //   );
  // }

  if (flag === 'login1') {
    return (
      <div className={currentMode === 'Dark' ? 'dark' : ''}>
        <BrowserRouter>
          <div className="flex relative dark:bg-main-dark-bg">
            <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}>
              <TooltipComponent content="Settings" position="Top">
                <button
                  type="button"
                  onClick={() => setThemeSettings(true)}
                  style={{ background: currentColor, borderRadius: '50%' }}
                  className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                >
                  <FiSettings />
                </button>
              </TooltipComponent>
            </div>
            {activeMenu ? (
              <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
                <Sidebar />
              </div>
            ) : (
              <div className="w-0 dark:bg-secondary-dark-bg">
                <Sidebar />
              </div>
            )}
            <div
              className={
                activeMenu
                  ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  '
                  : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
              }
            >
              <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
                <Navbar />
              </div>
              <div>
                {themeSettings && <ThemeSettings />}
                <Routes>
                  {/* dashboard  */}
                  <Route path="/" element={<Ecommerce />} />
                  <Route path="/home" element={<Ecommerce />} />
                  <Route path="/tasks" element={<Tasks />} />

                  {/* New */}
                  <Route path="/Employee" element={<NewEmployee />} />
                  <Route path="/Assest" element={<NewAssest />} />
                  <Route path="/PartyDiscount" element={<PartyDiscount />} />
                  <Route path="/partydisc" element={<ViewPartyDiscount />} />

                  {/* bill  */}
                  <Route path="/Electric" element={<ElectricBill />} />
                  <Route path="/Telephone" element={<TelephoneBill />} />

                  {/* pages  */}
                  <Route path="/assests" element={<Orders />} />
                  <Route path="/employees" element={<Employees />} />
                  <Route path="/customers" element={<Customers />} />
                  <Route path="/Add-insurance" element={<Insurance />} />
                  <Route path="/Insurance" element={<Insurancedata />} />
                  <Route path="/Add-vehicle" element={<Vehicle />} />
                  <Route path="/vehicle" element={<ViewVehicle />} />
                  <Route path="/Taxpayment" element={<TaxPayment />} />
                  <Route path="/Tax" element={<ViewTax />} />
                  <Route path="/reminder" element={<Reminder />} />
                  <Route
                    path="/shopregistration"
                    element={<ShopRegistration />}
                  />
                  <Route path="/shopReg" element={<ViewShopRegistration />} />
                  <Route path="/bill" element={<ViewElectric />} />
                  {/* apps  */}
                  {/* <Route path="/kanban" element={<Kanban />} />
                  <Route path="/editor" element={<Editor />} />
                  <Route path="/color-picker" element={<ColorPicker />} /> */}

                  {/* charts  */}
                  {/* <Route path="/line" element={<Line />} />
                  <Route path="/bar" element={<Bar />} />
                  <Route path="/pie" element={<Pie />} />
                  <Route path="/financial" element={<Financial />} />
                  <Route path="/color-mapping" element={<ColorMapping />} />
                  <Route path="/pyramid" element={<Pyramid />} />
                  <Route path="/stacked" element={<Stacked />} /> */}
                  <Route path="/landingpage" element={<LandingPage />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="*" element={<Ecommerce />} />
                </Routes>
              </div>
              <Footer />
            </div>
          </div>
        </BrowserRouter>
      </div>
    );
  }
  if (flag === 'login2') {
    return (
      <div className={currentMode === 'Dark' ? 'dark' : ''}>
        <BrowserRouter>
          <div className="flex relative dark:bg-main-dark-bg">
            <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}>
              <TooltipComponent content="Settings" position="Top">
                <button
                  type="button"
                  onClick={() => setThemeSettings(true)}
                  style={{ background: currentColor, borderRadius: '50%' }}
                  className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                >
                  <FiSettings />
                </button>
              </TooltipComponent>
            </div>
            {activeMenu ? (
              <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
                <AdminSidebar />
              </div>
            ) : (
              <div className="w-0 dark:bg-secondary-dark-bg">
                <AdminSidebar />
              </div>
            )}
            <div
              className={
                activeMenu
                  ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  '
                  : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
              }
            >
              <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
                <Navbar />
              </div>
              <div>
                {themeSettings && <ThemeSettings />}
                <Routes>
                  {/* dashboard  */}
                  {/* <Route path="/" element={<Branch />} /> */}
                  <Route path="/" element={<Ecommerce />} />
                  <Route path="/home" element={<Ecommerce />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/tasks" element={<Tasks />} />

                  {/* New */}
                  <Route path="/Employee" element={<NewEmployee />} />
                  <Route path="/Assest" element={<NewAssest />} />
                  <Route path="/PartyDiscount" element={<PartyDiscount />} />
                  <Route path="/partydisc" element={<ViewPartyDiscount />} />

                  {/* bill  */}
                  <Route path="/Electric" element={<ElectricBill />} />
                  <Route path="/Telephone" element={<TelephoneBill />} />

                  {/* pages  */}
                  <Route path="/assests" element={<Orders />} />
                  <Route path="/employees" element={<Employees />} />
                  <Route path="/customers" element={<Customers />} />
                  <Route path="/Add-insurance" element={<Insurance />} />
                  <Route path="/Insurance" element={<Insurancedata />} />
                  <Route path="/Add-vehicle" element={<Vehicle />} />
                  <Route path="/vehicle" element={<ViewVehicle />} />
                  <Route path="/Taxpayment" element={<TaxPayment />} />
                  <Route path="/Tax" element={<ViewTax />} />
                  <Route path="/reminder" element={<Reminder />} />
                  <Route
                    path="/shopregistration"
                    element={<ShopRegistration />}
                  />
                  <Route path="/shopReg" element={<ViewShopRegistration />} />
                  <Route path="/bill" element={<ViewElectric />} />
                  {/* apps  */}
                  {/* <Route path="/kanban" element={<Kanban />} /> */}
                  {/* <Route path="/editor" element={<Editor />} /> */}
                  {/* <Route path="/color-picker" element={<ColorPicker />} /> */}

                  {/* charts  */}
                  {/* <Route path="/line" element={<Line />} />
                  <Route path="/bar" element={<Bar />} />
                  <Route path="/pie" element={<Pie />} />
                  <Route path="/financial" element={<Financial />} />
                  <Route path="/color-mapping" element={<ColorMapping />} />
                  <Route path="/pyramid" element={<Pyramid />} />
                  <Route path="/stacked" element={<Stacked />} /> */}
                  <Route path="/landingpage" element={<LandingPage />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="*" element={<Ecommerce />} />
                </Routes>
              </div>
              <Footer />
            </div>
          </div>
        </BrowserRouter>
      </div>
    );
  }

  if (flag === 'login') {
    return <LoginPage />;
  }

  // if (flag === 'logout') {
  //   return <LoginPage />;
  // }

  return (
    <div className="bg-slate-200">
      <LandingPage />
    </div>
  );
};

export default App;
