import React, { useState } from 'react';
import { Text, Input, Grid, Button } from '@nextui-org/react';

const { gapi } = window;
const CLIENT_ID = '671152913310-mb7ql91dtjp2bqoa18p2d8j0fnf1mndj.apps.googleusercontent.com';
const API_KEY = 'AIzaSyCWEnJyuFB7mGYesDjTde5iz9xCkNPUfmU';
const DISCOVERY_DOCS = [
  'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
];
const SCOPES = 'https://www.googleapis.com/auth/calendar.events';

export default function Reminder() {
  const [EventName, setEventName] = useState('');
  const [EventLoc, setEventLoc] = useState('');
  const [EventDesc, setEventDesc] = useState('');
  const [EventFDate, setEventFDate] = useState('');
  const [EventTDate, setEventTDate] = useState('');
  const handleClick = () => {
    gapi.load('client:auth2', () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        plugin_name: 'chat',
        scope: SCOPES,
      });

      // eslint-disable-next-line no-console
      gapi.client.load('calendar', 'v3', () => console.log('bam!'));

      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          const event = {
            summary: EventName,
            location: EventLoc,
            description: EventDesc,
            start: {
              dateTime: EventFDate.concat('T04:30:00-00:00'),
              timeZone: 'Asia/Kolkata',
            },
            end: {
              dateTime: EventTDate.concat('T04:30:00-00:00'),
              timeZone: 'Asia/Kolkata',
            },
            recurrence: ['RRULE:FREQ=DAILY;COUNT=1'],
            attendees: [
              { email: 'dineshlal29@gmail.com' },
              { email: 'freelancervnd777@gmail.com' },
              { email: 'souravpaul210789@gmail.com' },
            ],
            reminders: {
              useDefault: false,
              overrides: [
                { method: 'email', minutes: 24 * 60 },
                { method: 'popup', minutes: 10 },
              ],
            },
          };

          const request = gapi.client.calendar.events.insert({
            calendarId: 'primary',
            resource: event,
          });

          // eslint-disable-next-line no-shadow
          request.execute((event) => {
            window.open(event.htmlLink);
          });

          gapi.client.calendar.events
            .list({
              calendarId: 'primary',
              timeMin: new Date().toISOString(),
              showDeleted: false,
              singleEvents: true,
              maxResults: 10,
              orderBy: 'startTime',
            })
            .then((response) => {
              const events = response.result.items;
              // eslint-disable-next-line no-console
              console.log('EVENTS: ', events);
            });
        });
    });
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Text
        h1
        size={30}
        css={{
          textGradient: '45deg, $blue600 -20%, $blue600 50%',
        }}
        weight="bold"
      >
        CREATE REMINDER
      </Text>
      <Grid>
        <Input
          label="Event Name"
          type="text"
          placeholder="Enter Event Name"
          name="EventName"
          onChange={(e) => setEventName(e.target.value)}
          value={EventName}
        />
      </Grid>
      <Grid>
        <Input
          label="Event Location"
          type="text"
          placeholder="Enter Event Location"
          name="EventLoc"
          onChange={(e) => setEventLoc(e.target.value)}
          value={EventLoc}
        />
      </Grid>
      <Grid>
        <Input
          label="Event Description"
          type="text"
          placeholder="Enter Short Event Description"
          name="EventDesc"
          onChange={(e) => setEventDesc(e.target.value)}
          value={EventDesc}
        />
      </Grid>
      <Grid>
        <Input
          width="186px"
          label="Remind From Date"
          type="date"
          placeholder="date"
          name="EventFDate"
          onChange={(e) => setEventFDate(e.target.value)}
          value={EventFDate}
        />
      </Grid>
      <Grid>
        <Input
          width="186px"
          label="Remind Till Date"
          type="date"
          placeholder="date"
          name="EventTDate"
          onChange={(e) => setEventTDate(e.target.value)}
          value={EventTDate}
        />
      </Grid>
      <br />
      <Grid>
        <Button shadow color="primary" auto onClick={handleClick}>
          Submit
        </Button>
      </Grid>
    </div>
  );
}
