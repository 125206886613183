import { useState, useMemo } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';

import { Dropdown } from '@nextui-org/react';

import glogo from '../data/goenkas2.png';
// import wedding from '../data/bride-groom.jpg';
// import background from '../data/bglogReg.png';
import { useStateContext } from '../contexts/ContextProvider';

export default function Register() {
  // const [name, setName] = useState('');
  // const [password, setPassword] = useState('');
  // const [cpassword, setCpassword] = useState('');
  // const navigate = useNavigate();
  const { register, updateUserName } = useStateContext();
  const [selected, setSelected] = useState(new Set(['Select Branch']));
  const selectedValue = useMemo(() => Array.from(selected).join(', ').replaceAll('_', ' '), [selected]);

  const registerUser = async (event) => {
    event.preventDefault();

    const from = event.target;
    const name = from.name.value;
    const email = from.email.value;
    const password = from.password.value;
    register(email, password)
      .then(({ user }) => {
        console.log(user);
        const upUser = {
          displayName: name,
        };
        updateUserName(upUser)
          .then(() => {
            // eslint-disable-next-line no-alert
            alert('User Registration is successfull');
          })
          .catch((error) => {
            console.error(error);
            // eslint-disable-next-line no-alert
            alert('User Registration is Not successfull');
          });
      })
      .catch((error) => console.error(error));
    // const body = {
    //     name: name,
    //     email: email,
    //     password: password
    // }
    // const response = await axios.post('https://localhost:5000/register', body)
    // const data = await response.data

    // const response = await fetch('http://localhost:5000/register', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({
    //     name,
    //     password,
    //     cpassword,
    //   }),
    // });

    // const data = await response.json();

    // if (data.status === 'OK') {
    //   alert('User Registered Successfully!');
    //   window.location.href = '/loginpage';
    // } else {
    //   alert('User Registration Failed!');
    // }
  };

  return (
    <div
      className="w-full bg-gradient-to-bl from-red-500 via-salte-100 px-10 font-Roboto_Condensed"
    //   style={
    // { backgroundImage: `url(${background})`,
    // }
    //   }
    >
      <div className="w-11/12 h-screen mx-auto flex justify-center items-center">
        <div className="hidden xl:block">
          <img
            className="rounded-l-lg"
            src="https://images.unsplash.com/photo-1524758631624-e2822e304c36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
            alt="bgImage"
          />
        </div>
        <div className="bg-white lg:flex-row rounded-lg w-full lg:w-10/12 mx-auto px-4 py-8 my-40">
          <img className="w-2/5 mx-auto mb-8" src={glogo} alt="goenka's logo" />
          <div className="w-full px-3 lg:px-8">
            <h2 className="text-2xl mb-5 text-slate-900">Create User</h2>
            <form onSubmit={registerUser}>
              <div className="mt-5">
                <p className="text-slate-600 text-lg mb- italic">Full Name</p>
                <input
                  type="text"
                  className="border-b-2 border-blue-900 bg-transparent outline-none px-2 w-full text-slate-900"
                  name="name"
                />
              </div>
              <div className="mt-5">
                <p className="text-slate-600 text-lg mb- italic">Email</p>
                <input
                  type="email"
                  className="border-b-2 border-blue-900 bg-transparent outline-none px-2 w-full text-slate-900"
                  name="email"
                />
              </div>
              <div className="mt-5">
                <p className="text-slate-600 text-lg mb- italic">Password</p>
                <input
                  type="password"
                  className="border-b-2 border-blue-900 bg-transparent outline-none px-2 w-full text-slate-900"
                  name="password"
                />
              </div>
              <div className="mt-5">
                <p className="text-slate-600 text-lg mb- italic">Confirm Password</p>
                <input
                  type="password"
                  className="border-b-2 border-blue-900 bg-transparent outline-none px-2 w-full text-slate-900"
                />
              </div>
              <br />
              <p className="text-slate-600 font-light text-lg mb-2">Select Your Branch</p>
              <Dropdown>
                <Dropdown.Button flat color="secondary" css={{ tt: 'capitalize' }}>
                  {selectedValue}
                </Dropdown.Button>
                <Dropdown.Menu
                  aria-label="Single selection actions"
                  color="secondary"
                  disallowEmptySelection
                  selectionMode="single"
                  selectedKeys={selected}
                  onSelectionChange={setSelected}
                >
                  <Dropdown.Item key="Bamboo Flat">Bamboo Flat</Dropdown.Item>
                  <Dropdown.Item key="Aberdeen Bazar">Aberdeen Bazar</Dropdown.Item>
                  <Dropdown.Item key="Diglipur">Diglipur</Dropdown.Item>
                  <Dropdown.Item key="Bathu Basti">Bathu Basti</Dropdown.Item>
                  <Dropdown.Item key="Lamba Line">Lamba Line</Dropdown.Item>
                  <Dropdown.Item key="Minnie Bay">Minnie Bay</Dropdown.Item>
                  <Dropdown.Item key="Goenkas Trends">Goenkas Trends</Dropdown.Item>
                  <Dropdown.Item key="Zola">Zola</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className="mt-12 text-center">
                <input
                  type="submit"
                  className="w-4/5 text-center bg-gradient-to-tr from-blue-500 via-blue-600 to-blue-700 py-3 text-white cursor-pointer rounded-full"
                  value="Register Now"
                />
              </div>
              {/* <div className="mt-5 mx-auto text-center">
                <a href="/loginpage" className="mt-5 text-center font-display hover:text-sky-700">
                  Already a user? ... Click Here
                </a>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
