/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ColumnDirective, ColumnsDirective, Edit, GridComponent, Inject, Page, Toolbar } from '@syncfusion/ej2-react-grids';
import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import { useStateContext } from '../contexts/ContextProvider';

function DialogEdit() {
  const { location, isAdmin } = useStateContext();
  console.log("🚀 ~ file: Employees.jsx:12 ~ DialogEdit ~ location:", location)
  const toolbarOptions = ['Edit', 'Delete', 'Search'];
  const editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog' };
  const pageSettings = { pageCount: 10 };
  const [user, setUser] = useState([]);


  const [uniqueLocations, setUniqueLocations] = useState([]);
  const [filteredData, setFilteredData] = useState(user.data || []);

  const filteredUserData = isAdmin ? filteredData : user?.data?.filter((item) => item.Location.toLowerCase() === location.toLowerCase());
  const fetchData = () => fetch('https://gapi.nxtunicorns.com/api/employee')
    .then((response) => response.json())
    .then((data) => setUser(data));

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (uniqueLocations.includes('All')) {
      setFilteredData(user?.data || []);
    }
  },[uniqueLocations,user?.data]);

  useEffect(() => {
    const locations = user.data?.map((item) => item?.Location); // const locations = user.data?.map((item) => item.Location === user.branch) || [];
    const uniqueLocationss = [...new Set(locations)];
    setUniqueLocations(['All', ...uniqueLocationss]);
  }, [user.data]);


  // useEffect(() => {
  //   if (uniqueLocations.includes('All')) {
  //     setFilteredData(user?.data || []);
  //   }
  // },[uniqueLocations,user?.data]);
  // if (uniqueLocations.includes('All')) {
  //   gridRef.current.currentViewData = user.data;
  // }

  const handleLocationFilter = (args) => {
    const selectedLocation = args.value;
    if (selectedLocation === 'All') {
      setFilteredData(user?.data || []);
    } else {
      const filteredSelectedLocationData = user.data.filter((item) => item.Location === selectedLocation);
      setFilteredData(filteredSelectedLocationData);
    }
  };
  async function actionComplete(args) {
    console.log(args);
    if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
      console.log(args);
    }
    if (args.requestType === 'delete') {
      console.log(args.data[0].id);
      try {
        const response = await fetch(`https://gapi.nxtunicorns.com/api/employee/${args.data[0].id}`, {
          method: 'DELETE',
        });
        const datas = await response.json();
        if (datas !== null) {
          swal({
            title: 'Record Deleted!',
            text: 'DELETED',
            icon: 'success',
            button: 'Continue',
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
    if (args.requestType === 'edit') {
      console.log(args);
    }
  }
  return (
    <div className="control-pane pt-20 lg:pt-2">
      <div className="control-section">
        <GridComponent  dataSource={filteredUserData} toolbar={toolbarOptions} allowPaging editSettings={editSettings} pageSettings={pageSettings} actionComplete={actionComplete.bind(this)}>
          <ColumnsDirective>
            <ColumnDirective field="name" headerText="Name" width="120" textAlign="Center" />
            <ColumnDirective field="title" headerText="Designation" width="150" textAlign="Center" />
            <ColumnDirective field="HiringDate" headerText="DOJ" width="120" textAlign="Center" format="yMd" editType="datepickeredit" />
            <ColumnDirective field="DOB" headerText="DOB" editType="datepickeredit" textAlign="Center" width="170" format="yMd" />
            <ColumnDirective field="DateofAnniversary" headerText="DOA" width="150" editType="datepickeredit" textAlign="Center" format="yMd" />
            <ColumnDirective field="Mobile" headerText="Mobile Number" width="120" textAlign="Center" />
            <ColumnDirective field="PresentAddress" headerText="Permanent Address" width="150" textAlign="Center" />
            <ColumnDirective field="CurrentAddress" headerText="Current Address" width="120" textAlign="Center" />
            <ColumnDirective field="GuardianName" headerText="Guardian Name" textAlign="Center" width="170" />
            <ColumnDirective field="GuardianRelation" headerText="Guardian Relation" width="150" textAlign="Center" />
            <ColumnDirective field="GuardianNo" headerText="Guardian Number" width="150" textAlign="Center" />
            <ColumnDirective field="EmployeeImage" headerText="Photo" width="150" textAlign="Center" />
            <ColumnDirective field="Location" headerText="Location" width="150" textAlign="Center" />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Edit]} />
          <div className="flex justify-end">
            <div className="select-wrap">
              {uniqueLocations.length > 0 && (
              <DropDownListComponent
                className="border"
                // readonly={!isAdmin}
                id="ddlelement"
                dataSource={isAdmin ? uniqueLocations : [location]}
                placeholder="Filter By Location"
                fields={{ text: 'Location', value: 'Location' }}
                value={location} // {user.branch}
                change={handleLocationFilter}
                width="200px"
              />
              )}
            </div>
          </div>
        </GridComponent>
      </div>
    </div>
  );
}
export default DialogEdit;
