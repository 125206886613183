/* eslint-disable react/button-has-type */
import { useState } from 'react';
import { FaPalette, FaHandPointUp, FaUserFriends } from 'react-icons/fa';

function DetailedSection() {
  const [readMore, setReadMore] = useState(false);

  return (
    <div className="my-20">
      <div className="w-11/12 mx-auto flex flex-col lg:flex-row items-center justify-center">
        <div className="mr-10">
          <img
            className="rounded-lg"
            src="https://images.unsplash.com/photo-1628258334105-2a0b3d6efee1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
            alt=""
          />
        </div>
        <div className="w-full lg:w-6/12">
          <h4 className="text-slate-500 font-bold text-lg">Core Features</h4>
          <h1 className="text-4xl lg:text-6xl font-bold">
            Best Business Software Solution
          </h1>
          <div className="w-full lg:w-4/5 flex justify-between my-7">
            <div className="w-32 h-32 rounded-lg bg-white text-center flex flex-col items-center justify-center">
              <FaPalette className="text-rose-400 text-4xl mb-2" />
              <h5 className="font-bold">Streamline Workflow</h5>
            </div>
            <div className="w-32 h-32 rounded-lg bg-white text-center flex flex-col items-center justify-center">
              <FaHandPointUp className="text-rose-400 text-4xl mb-2" />
              <h5 className="font-bold">Inventory Management</h5>
            </div>
            <div className="w-32 h-32 rounded-lg bg-white text-center flex flex-col items-center justify-center">
              <FaUserFriends className="text-rose-400 text-4xl mb-2" />
              <h5 className="font-bold">Assest Management</h5>
            </div>
          </div>
          <p className="text-slate-600 text-lg w-full lg:w-4/5 mb-7">
            This App is co-powered by Unicorniz Innovations, a leading
            technology company with a proven track record of delivering
            innovative solutions to businesses across industries.
          </p>
          {readMore && (
            <p>
              <p className="text-slate-600 text-lg w-full lg:w-4/5 mb-7">
                With their expertise and our dedication to customer
                satisfaction, we&apos;re confident that the Goenkas Management
                App will exceed your expectations and help you achieve your
                business goals.
              </p>
              <p className="text-slate-600 text-lg w-full lg:w-4/5 mb-7">
                Check out their services here -&gt;
                <a
                  href="https://unicorniz.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="ml-2 font-bold bg-slate-900/10 text-slate-900 px-3 py-3 rounded-md hover:bg-orange-200 hover:text-slate-50"
                >
                  Unicorniz Innovations
                </a>
              </p>
            </p>
          )}
          <button
            onClick={() => {
              setReadMore(!readMore);
            }}
            className="bg-slate-700 text-white py-2 px-6 hover:bg-slate-900/5 hover:text-slate-600 rounded-sm"
          >
            {readMore ? 'Read Less' : 'Read More'}
          </button>
        </div>
      </div>
      <div className="w-11/12 mx-auto flex flex-col-reverse lg:flex-row items-center justify-center px-10 my-20 bg-white rounded-lg">
        <div className="flex-col space-y-5">
          <h1 className="text-4xl lg:text-5xl font-bold mb-10">
            Operate Directly from SmartPhone
          </h1>
          <p className="flex-col text-slate-600 text-lg w-full lg:w-4/5 mb-7">
            This is a Cloud Based Web App, that allows you to manage your work
            from any device.
          </p>
          <p className="flex-col text-slate-600 text-lg w-full lg:w-4/5 mb-7">
            The App is simple, straightforward and designed in such a way that
            it is compatible with any screen regardless of it&apos;s screen
            size.
          </p>
        </div>
        <div>
          <img
            className="h-96 mt-[-50px] rounded-t-lg"
            src="https://images.unsplash.com/photo-1519336305162-4b6ed6b6fc83?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default DetailedSection;
