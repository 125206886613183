import React, { useState } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
// import { Link } from 'react-router-dom';

// import LoginPage from '../pages/LoginPage';
import { Button } from '.';
import { userProfileData } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';
// import avatar3 from '../data/avatar3.jpg';

function UserProfile() {
  const { currentColor, setflag, user } = useStateContext();
  const [pageTask, setPageTask] = useState('');

  const pageTaskRedirect = () => {
    if (user.email === 'rakesh123@gmail.com') {
      setPageTask(
        'https://www.notion.so/Rakesh-85f3c95ff98f429dbfe40f7a86ea322b',
      );
    } else if (user.email === 'santosh123@gmail.com') {
      setPageTask(
        'https://www.notion.so/Santosh-b96b45d82be4478c8f4aae18dee150a2',
      );
    } else if (user.email === 'sourav123@gmail.com') {
      setPageTask(
        'https://www.notion.so/Arun-40ef298335f44e0ba2aa4c34712f2a03',
      );
    }
  };

  return (
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200">User Profile</p>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        {/* <img
          className="rounded-full h-24 w-24"
          src={avatar3}
          alt="user-profile"
        /> */}
        <div>
          <p className="font-semibold text-xl dark:text-gray-200">
            {user.displayName}
          </p>
        </div>
      </div>
      {/* <div>
        <a href={pageTask} target="_blank" rel="noreferrer">
          {userProfileData.map((item, index) => (
            <div
              key={index}
              className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#42464D]"
              onClick={pageTaskRedirect}
            >
              <button
                type="button"
                style={{ color: item.iconColor, backgroundColor: item.iconBg }}
                className=" text-xl rounded-lg p-3 hover:bg-light-gray"
              >
                {item.icon}
              </button>

              <div>
                <p className="font-semibold dark:text-gray-200 ">
                  {item.title}
                </p>
                <p className="text-gray-500 text-sm dark:text-gray-400">
                  {' '}
                  {item.desc}{' '}
                </p>
              </div>
            </div>
          ))}
        </a>
      </div> */}

      <div className="mt-5">
        <a href="/">
          <Button
            color="white"
            bgColor={currentColor}
            text="Logout"
            borderRadius="10px"
            width="full"
            onClick={() => setflag('logout')}
          />
        </a>
      </div>
    </div>
  );
}

export default UserProfile;
